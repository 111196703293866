<template>
  <v-app>
    <div class="tw-mb-20">
      <Header>
        <template v-slot:right-header-component>
          <ProfileDropDown/>
        </template>
      </Header>
    </div>
    <div>
      <keep-alive>
        <component v-bind:is="returnMainContent()"></component>
      </keep-alive>
    </div>

  </v-app>
</template>

<script>
import Header from "@/components/reusables/Header";
import ProfileDropDown from "@/components/reusables/ProfileDropDown";
import SkillCard from "@/components/dashboard/components/Vetting/SkillCard";
import eventBus from "@/eventBus";
import SkillsDetail from "@/components/dashboard/components/Vetting/SkillsDetail";
import {mapState} from "vuex";
import CalendlySchedule from "@/components/dashboard/components/Vetting/CalendlySchedule";
import VettingProcess from "@/components/dashboard/components/Vetting/VettingProcess";
import AssessmentStage from "@/components/dashboard/components/Vetting/AssessmentStage";
import AssessmentOverview from "@/components/dashboard/components/Vetting/AssessmentOverview";

export default {
  name: "Vetting",
  components: {AssessmentOverview,SkillsDetail, SkillCard, ProfileDropDown, Header},
  mounted() {
    eventBus.$on('send', (val) => {
      this.skill = val
    });
  },
  computed: {
    ...mapState('applicantProfile', ['getApplicantProfile'])
  },
  methods: {
    returnMainContent() {
      let component = undefined
      if (this.$route.query.tab === '0') {
        component = VettingProcess;
      } else if (this.$route.query.tab === "1") {
        component = SkillCard;
      } else if (this.$route.query.tab === "2") {
        component = AssessmentStage;
      } else if (this.$route.query.tab === "3") {
        component = AssessmentOverview;
      } else if (this.$route.query.tab === "4") {
        component = CalendlySchedule;
      } else if (this.$route.query.tab === "5") {
        component = SkillsDetail;
      } else {
        component = VettingProcess;
      }
      return component
    },
  },
  data() {
    return {
      skill: '',
      loading: false,
    }
  },
}
</script>

<style scoped>

</style>