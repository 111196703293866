<template>
<div class="d-flex empty flex-column align-center justify-center">
<slot></slot>
</div>
</template>

<script>
export default {
name: "CompanyProfileEmptyState"
}
</script>

<style scoped>
.empty{
  min-height: 40vh;
}
</style>