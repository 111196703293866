<template>
  <div>
    <div class="tw-py-3 tw-h-48 tw-bg-no-repeat tw-w-full"
         :style="{background:`url(${imageBg}) no-repeat`,backgroundSize:'cover'}" id="background">
      <div class="background_cover_image">
        <v-img alt="" class="enumForAllImage" :src="imageBg"/>
      </div>
      <div class="tw-p-2 position tw-w-full">

        <div class="lg:tw-pl-60 tw-ml-5 tw-pt-5">
          <div>
            <div class="tw-flex align-center tw-cursor-pointer" @click="goBack()">
              <vue-feather type="arrow-left" style="width: 20px;color: #9CABB5" class="mr-2"/>
              <span style="font-size: 14px">Back</span>
            </div>
          </div>
          <div class="lg:tw-ml-32 md:tw-ml-5 tw-pt-2">
            <div class="title">
              <h2>Pre-Assessment Overview</h2>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="tw-p-6">

      <v-row no-gutters>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="10">
          <div class="lg:tw-ml-56 md:tw-ml-5 " style="max-width:600px ">
            <h4 class="tw-pb-4">
              Schedule Interview</h4>
              <div>
                <p>Click the button below to schedule an interview and choose
                  from the available date and time. You will receive an email with
                  a link to the interview. We advise that you set a reminder as you may
                  not be able to schedule another interview soon enough if missed.</p>
              <div>
                <h4 class="tw-pb-4">Interview</h4>
                <div>
                  <p>Join the meeting with the details sent to your email address and
                    come show us what you got. We will discuss your background and experience. </p>
                </div>
                <div>
                  <h4 class="tw-pb-4">Skills Test</h4>
                  <div>
                    <p>We will wrap up the interview with a pre-assessment test to understand your level
                      of competence at the skill you have selected. This pre-assessment will qualify you
                      for the main technical assessment.</p>
                  </div>

                </div>
            </div>

            <v-checkbox
                v-model="agree"
                color="purple"
                label="I have read and understood the instructions above"
                ripple
            >
              <template v-slot:label>
                <div class="label">I have read and understood the instructions above</div>
              </template>
            </v-checkbox>

            <div class="tw-pt-7">
              <BaseButton width="auto" id="scheduleInterview" depressed
                          style="border-radius: 8px !important;box-shadow: 1px 4px 4px #D5EDFF !important;"
                          button-text="Schedule Interview" :disabled="!agree" @click="goto()"/>
            </div>
          </div>
          </div>
        </v-col>
        <v-col cols="12" md="1"></v-col>
      </v-row>
          </div>
    </div>


</template>

<script>

import BaseButton from "@/components/reusables/BaseButton";

export default {
  name: "AssessmentOverview",
  components: {BaseButton},

  data() {
    return {
      agree: false,
      imageBg: require('@/assets/images/Frame 48095722.png'),
    }
  },
  methods:{
    goto(){
        this.$router.push({path:'/vetting',query:{tab: '4'}})
    },
    goBack(){
      this.$router.go(-1)
    }

  },


}
</script>

<style scoped lang="scss">
.title{
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #008EEF;
}
h4{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #001343;
}
p{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #4F4F4F
}
.position {
  position: absolute;
  //top: 40%;
  z-index: 2;
}
#background {
  position: relative;
  overflow-y: hidden;

  & .background_cover_image {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    opacity: 0.8;
  }
}
.label{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

</style>