<template>
  <div id="skillsDetail">
    <div class="tw-py-3 tw-h-60 tw-bg-no-repeat tw-w-full"
         :style="{background:`url(${imageBg}) no-repeat`,backgroundSize:'cover'}" id="background">
      <div class="background_cover_image">
        <v-img alt="" class="enumForAllImage" :src="image"/>
      </div>
      <div class="tw-p-5 position tw-w-full">

        <div class="lg:tw-pl-48 tw-ml-5 tw-pt-2">
          <div>
            <div class="tw-flex align-center tw-cursor-pointer" @click="goBack()">
              <vue-feather type="arrow-left" style="width: 20px;color: #9CABB5" class="mr-2"/>
              <span style="font-size: 14px">Back</span>
            </div>
          </div>
          <div class="lg:tw-ml-48 md:tw-ml-5 tw-pt-2">
            <div class="tw-flex">
              <h2>{{ skill.name }}</h2>
            </div>
            <div v-if="skill.duration" class="tw-flex align-center tw-pt-1.5">
              <vue-feather type="clock" class="tw-mr-2" style="width: 15px"/>
              <span class="duration" style="color: #475661;font-size: 14px">{{ getDuration(skill.duration) }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="tw-p-10">
      <v-row no-gutters>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="10">
          <div class="lg:tw-ml-52 md:tw-ml-5 " style="max-width: 766px">
            <h4 style="color: #475661" class="descFont tw-uppercase">
              {{ skill.is_enum ? 'Instruction' : 'next step' }}</h4>
            <div class="tw-py-5 desc">
              <div v-if="skill.is_enum">
                <h4>Make sure you have a stable internet connection before you start the test.</h4>
                <ol style="list-style: disc" class="tw-mb-5 tw-pt-">
                  <li>Once test has begun, you cannot go back as you would be forced to submit.</li>
                  <li>Once you have submitted an answer, you can’t go back</li>
                  <li>You may exit the test, but the timer will continue to run</li>
                </ol>
                <p>|Question Bank Instructions|</p>
                Attempt all questions.
              </div>
              <div v-else>
                <p>A link to this test will be forwarded to your registered email address.</p>

                <p class="tw-py-3">Please be informed that for tests in this category, you can only take one
                  as it is considered your primary area of expertise.</p>

                <p>We wish you success!</p>
              </div>
            </div>

            <v-checkbox
                v-model="agree"
                color="purple"
                label="I have read and understood the instructions above"
                ripple
            >
              <template v-slot:label>
                <div class="label">I have read and understood the instructions above</div>
              </template>
            </v-checkbox>

            <div class="tw-pt-10">
              <BaseButton width="auto" id="beginAssessment" depressed
                          style="border-radius: 8px !important;box-shadow: 1px 4px 4px #D5EDFF !important;"
                          button-text="Begin Assessment" :disabled="!agree" @click="makeAction(skill)"/>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="1"></v-col>
      </v-row>
    </div>
    <SkillsSuccessModal :skill="skill" :dialog="open" @close="open=false"/>
  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import SkillsSuccessModal from "@/components/dashboard/components/Vetting/SkillsSuccessModal";
import {viewFile} from "@/services/utils/aws";
import profileImage from "@/mixins/profile/profileImage";

export default {
  name: "SkillsDetail",
  components: {SkillsSuccessModal, BaseButton},
  mixins: [profileImage],

  watch: {
    getApplicantProfile: {
      handler: function (profile) {
        if (!profile.image) return
        const image = JSON.parse(profile.image).key
        viewFile({url: image, context: this})
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      agree: false,
      imageBg: require('@/assets/images/Frame 48095722.png'),
      open: false,
      get_skill: {},
      image: [String],
    }
  },


  computed: {
    skill() {
      return JSON.parse(sessionStorage.getItem('skill'))
    }
  },
  methods: {
    getDuration(val) {
      let duration
      if (+val / 60 < 1) {
        duration = val + ' mins'
      } else {
        let hr = val / 60
        let remainder = val % 60
        duration = (Math.round(hr) + (hr > 1 ? ' hrs' : ' hr')) + ' ' + (remainder + (remainder > 1 ? ' mins' : 'min'))
      }

      return duration
    },
    makeAction(val) {
      if (val.is_enum) {
        this.$router.push({name: 'AssessmentQuestionView', query: {skill: val.id, category: 'skill assessment'}})
      } else this.open = true
    },
    goBack() {
      this.$router.go(-1)
      // this.$emit('change')
    }
  },
}
</script>

<style scoped lang="scss">


h2 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #008EEF;
}

.desc {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}

.position {
  position: absolute;
  //top: 40%;
  z-index: 2;
}

.descFont {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #475661;

}

#background {
  position: relative;
  overflow-y: hidden;

  & .background_cover_image {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    opacity: 0.8;
  }
}

.label {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
</style>