<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="430"
        transition="dialog-top-transition"
        persistent
    >
      <v-card class="tw-p-3 md:tw-p-5 cardBack">
        <div class="tw-flex tw-justify-end">
          <v-btn icon text @click="$emit('close')">
            <v-icon color="black" class="ml-2">mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="inner-content">
          <div class="mb-8">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="80" height="80" rx="40" fill="#E1F3FF"/>
              <rect x="8" y="8" width="64" height="64" rx="32" fill="#F7FCFF"/>
              <path d="M53.3334 26.6667L38.6667 41.3333" stroke="#475661" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M53.3334 26.6667L44.0001 53.3333L38.6667 41.3333L26.6667 36L53.3334 26.6667Z" stroke="#475661"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <p class="inner-title">{{ text }}</p>
        </div>
        <div class="tw-flex tw-justify-center align-center mb-2 mt-5">
          <Base-button class="mr-4" depressed button-text="Got it" @click="sendMail"></Base-button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import BaseButton from "@/components/reusables/BaseButton";
import {inviteForExternalTest} from "@/services/api/APIService";

export default {
  name: "SkillsSuccessModal",
  components: {BaseButton},
  props: {
    skill: [Object],
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data: () => {
    return {
      text: "Test link has been sent to your registered email address. Good luck.",
      noImage: 'https://images.unsplash.com/photo-1632169505206-9506f48761e6?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    }
  },
  methods: {
    async sendMail() {
      this.loading = true
      try {
        let skill = {
          ...this.skill,
          test_id: this.skill.id
        }
        await inviteForExternalTest(skill)
        this.$displaySnackbar({message: 'Invite Sent', success: true})
        await this.$router.push({name: 'Vetting'})

      } catch (e) {
        this.$displaySnackbar({message: e.response.data.message, success: false})
      }finally {
        this.loading = false
      }
      this.closeModal()
    },
    closeModal() {
      this.$emit('close')
    },
  }
}
</script>

<style scoped lang="scss">
.cardBack {
  width: auto;
  min-height: 291px;
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}

.inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "DM Sans", serif;
  line-height: 31px;

}

.main-title {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475661;
  @media (min-width: 650px) {
    white-space: pre;
  }
}

.inner-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #475661;

}
</style>