<template>
  <div class="tw-p-3">
    <v-row no-gutters>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <div class="tw-mt-5 lg:tw-ml-24 tw-ml-5">
          <div>
            <div class="tw-flex align-center tw-cursor-pointer" @click="goBack()">
              <vue-feather type="arrow-left" style="width: 20px" class="mr-2"/>
              <span style="font-size: 14px">Back</span>
            </div>
          </div>
          <div class="tw-py-8">
            <h2>Skill Assessment</h2>
          </div>
          <div class="lg:tw-mr-36">
            <v-divider style="border: 1px solid #EAEAEA;"/>
          </div>

          <div v-if="isLoading">
            <Loader/>
          </div>
          <div v-else class="tw-py-10 lg:tw-px-20">
            <div class="tw-pb-10 tw-pt-5 tw-ml-7">
              <v-text-field v-if="!show"
                            v-model="search"
                            hide-details
                            style="width: 352px"
                            placeholder="Search a skill"
                            solo
              />
            </div>
            <div v-if="!isLoading &&!searchList.length ">
              <company-profile-empty-state>
                <EmptySkills/>
                <h2 class="tw-pt-8 textSkills">No skill found</h2>
              </company-profile-empty-state>
            </div>
            <div class="tw-flex tw-flex-wrap">

              <div class="tw-mx-7 tw-my-5" v-for="(skill,i) in searchList" :key="i">
                <v-card :disabled="skill.is_already_taken" flat class="card tw-py-4 tw-px-7" @click="getSkill(skill)">
                  <h4 class="name">{{ skill.name }}</h4>
                  <div v-if="skill.duration" class="tw-flex align-center tw-pt-1.5">
                    <vue-feather type="clock" class="tw-mr-2" style="width: 15px"/>
                    <span class="duration">{{getDuration(skill.duration) }}</span>
                  </div>
                </v-card>
              </div>
            </div>

          </div>
        </div>

      </v-col>
      <v-col cols="12" md="1"></v-col>
    </v-row>
  </div>
</template>

<script>
import CompanyProfileEmptyState from "@/components/viewCompanyProfile/reuseables/CompanyProfileEmptyState";
import EmptySkills from "@/components/dashboard/components/Vetting/EmptySkills";
import eventBus from "@/eventBus";
import {mapGetters} from "vuex";
import Loader from "@/Loader";

export default {
  name: "SkillCard",
  components: {Loader, EmptySkills, CompanyProfileEmptyState},
  data() {
    return {
      isLoading: false,
      search: '',
      show: false,
      searchList: [],
    }
  },
  computed: {
    ...mapGetters('applicantProfile', ['getSkills'])
  },
  watch: {
    getSkills: {
      handler(val) {
        this.searchList = val
      },
      immediate: true
    },
    search: function (newValue, oldValue) {
      console.log(oldValue)
      this.searchList = [...this.getSkills.filter(pool => (pool.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
    }
  },
  async mounted() {
    this.isLoading = true
    if (!this.getSkills.length) {
      await this.$store.dispatch('applicantProfile/getExternalSkills')
    }
    this.isLoading = false
  },
  methods: {
    getDuration(val) {
      let duration
      if (+val / 60 < 1) {
        duration = val + ' mins'
      } else {
        let hr = val / 60
        let remainder = val % 60
        duration = (Math.round(hr) + (hr >= 2 ? ' hrs' : ' hr')) + ' ' + (remainder + (remainder > 1 ? ' mins' : 'min'))
      }

      return duration
    },
    getSkill(val) {
      sessionStorage.setItem('skill', JSON.stringify(val))
      this.$router.push({query: {skillName: val.name, tab: '2'}})
      eventBus.$emit('send', val)
    },
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #142E70;
}

.card {
  width: 309.5px;
  min-height: 78px;
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  border-radius: 8px;
}

.card:hover {
  background: #F7FCFF;
  border: 1px solid #45B4FF;
}

.textSkills {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #1E323F;

}

.name {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 140%;
  color: #475661;
}

.duration {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  /*display: flex;*/
  /*align-items: center;*/
  letter-spacing: 0.01em;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 485px);
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #008EEF !important;
  max-height: 20px !important;
  border-radius: 6px;
}

.selected {
  width: 309.5px;
  height: 78px;
  background: #F7FCFF;
  border: 1px solid #45B4FF;
  border-radius: 8px;
}
</style>