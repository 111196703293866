<template>
  <div id="vettingProcess">
    <div class="tw-py-3 tw-h-60 tw-bg-no-repeat tw-w-full"
         :style="{background:`url(${imageBg}) no-repeat`,backgroundSize:'cover'}" id="background">
      <div class="background_cover_image">
        <v-img alt="" class="enumForAllImage" :src="imageBg"/>
      </div>
      <div class=" tw-p-5 position tw-w-full">

        <div class="lg:tw-pl-60  tw-pt-2">
          <div>
            <div class="tw-flex align-center tw-cursor-pointer" @click="goBack">
              <vue-feather type="arrow-left" style="width: 20px;color: #9CABB5" class="mr-2"/>
              <span style="font-size: 14px">Back</span>
            </div>
          </div>
          <div class="lg:tw-ml-48 tw-pt-2">
            <div class="headingTopic tw-flex">
              <h2>Vetting Process</h2>
            </div>
          </div>
        </div>
      </div>

    </div>
    <v-row no-gutters>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <div class="lg:tw-ml-72 tw-mx-5 tw-pt-10">

          <div>
            <h4 class="headingText tw-mb-2">Overview</h4>
            <h6 class="bodyText tw-mb-4">Get into Enum’s pool of vetted tech talent and
              find your desired tech job without job hunting. <span>Please note that <br/> you need a minimum of 3 years experience to be considered to join the Enum Talent pool.</span>
            </h6>
            <h4 class="headingText tw-mb-2">Skills Area</h4>
            <h6 class="bodyText tw-mb-4">Assessment is available for Vue, React, Java,
              Python, C# (.NET), Javascript (Node. JS), AWS, Azure, GCP, Flutter,  Kotlin.</h6>
            <h4 class="headingText tw-mb-2">Stage 1: Schedule an Interview</h4>
            <ol style="list-style: disc" class="tw-mb-4 bodyText">
              <li>Book your available date and time </li>
              <li>Get invited to a virtual interview </li>
              <li>Share your past work experience and projects </li>
              <li>Take a proctored pre-assessment </li>
            </ol>
            <h4 class="headingText tw-mb-2">Stage 2: Test Your Technical Skills</h4>
            <h6 class="bodyText tw-mb-7">If you passed the first stage you’d get an
              invite to a technical assessment. Attempt the test to vet your competence. Get vetted and matched.</h6>
          </div>

          <v-checkbox
              v-model="agreed"
              color="purple"
              label="I have read and understood the instructions above"
              ripple
          >
            <template v-slot:label>
              <div class="label">I have read and understood the instructions above</div>
            </template>
          </v-checkbox>

          <div class="tw-pt-5">
            <BaseButton width="113" id="beginAssessment" depressed :block="($vuetify.breakpoint.mobile)"
                        style="border-radius: 8px !important;box-shadow: 1px 4px 4px #D5EDFF !important;"
                        button-text="Proceed"
                        @click="next"
                        :disabled="!agreed"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="1"></v-col>
    </v-row>
  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";

export default {
  name: "VettingProcess",
  components: {BaseButton},

  data() {
    return {
      imageBg: require('@/assets/images/Frame 48095722.png'),
      agreed: false,
    }
  },
  methods: {
    goBack() {
      // this.$router.go(-1)
      this.$router.push({name: 'Dashboard'})
    },
    next() {
     this.$router.push({query:{tab:'1'}})
    }
  }
}
</script>

<style lang="scss" scoped>

.position {
  position: absolute;
  //top: 40%;
  z-index: 2;
}

#background {
  position: relative;
  overflow-y: hidden;

  & .background_cover_image {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    opacity: 0.8;
  }
}

.headingTopic {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #008EEF;
}

.headingText {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #001343;
}

.bodyText {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #4F4F4F;
}

.label {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

</style>