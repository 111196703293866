<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <div class="tw-flex-wrap tw-flex tw-justify-between tw-mt-10 lg:tw-mx-28 tw-ml-5" >
          <div class="tw-flex align-center tw-cursor-pointer" @click="goBack()" style="width: 70px">
            <vue-feather type="arrow-left" style="width: 20px;color: #9CABB5" class="mr-2"/>
            <span style="font-size: 14px">Back</span>
          </div>
          <div id="showBtn" v-show="show_finish">
            <base-button
                button-text="Finish"
                depressed
                @click="gotoDashboard"
            />
          </div>
        </div>
        <div>
          <vue-calendly
              class="calendly-inline-widget"
              style="min-width:320px;height:680px "
              :url="`https://calendly.com/enumafrica/enum-interview?hide_gdpr_banner=1&primary_color=008eef&name=${getUserInfo.name}&email=${getUserInfo.email}`"
              :height="640"
          ></vue-calendly>
        </div>

      </v-col>
      <v-col cols="12" md="1"></v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCalendly from 'vue-calendly';
import BaseButton from "@/components/reusables/BaseButton";
import {createVettingStatus} from "@/services/api/APIService";
Vue.use(VueCalendly);

// listening to events from calendly embedded widget
function isCalendlyEvent(e) {
  return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
}
window.addEventListener("message", function(e) {
  if(isCalendlyEvent(e)) {
    /* listening to confirm event scheduled */
    if (e.data.event === 'calendly.event_scheduled'){
      document.getElementById('showBtn').style.display = 'block';
    }
    // to get the payload of event
    // console.log("Event details:", e.data.payload);
  }
});

export default {
  name: "CalendlySchedule",
  components: {BaseButton},
  data(){
    return{
      show_finish:false
    }
  },
  computed:{
    getUserInfo(){
      let info = JSON.parse(sessionStorage.getItem('userData'))
      return info
    }
  },
  methods: {
    async gotoDashboard(){
      this.loading = true
      let skill = JSON.parse(sessionStorage.getItem('skill'))
      try {
        let data = {
          third_party_test_id: skill.id,
          third_party_cut_off_score: skill.cutoff_score,
          third_party_test_title: skill.name,
        }
        await createVettingStatus(data)
        this.$displaySnackbar({message: 'Successful', success: true})
        await this.$router.push({name:'Dashboard'})
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.message, success: false})
      }finally {
        this.loading = false
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>