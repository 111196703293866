<template>
  <div class="tw-mt-8">
    <div class="tw-flex lg:tw-gap-20">
      <div>
        <div class="lg:tw-pl-60 tw-ml-5 tw-pt-2">
          <div class="tw-flex align-center tw-cursor-pointer" @click="goBack()">
            <vue-feather type="arrow-left" style="width: 20px;color: #475661;" class="mr-2"/>
            <span style="font-size: 14px">Back</span>
          </div>
        </div>
      </div>
      <div class="tw-mt-32">
        <div>
          <p class="text">Assessment Stages</p>
        </div>
        <div class="tw-flex tw-flex-wrap tw-gap-10 tw-mt-12">
          <div class="tw-flex" v-for="(stage, i) in assessmentStages" :key="i" >
              <v-card :disabled="stage.level=== 'STAGE 2' && !stage.is_started" flat class="tw-py-4 tw-px-7 tw-flex tw-justify-between" :class="[getBorderColor(stage)]" >
                <div>
                  <h4 class="tw-pt-1">{{ stage.level }}</h4>
                  <p class="name tw-py-3">{{stage.stageName}}</p>
<!--                  <div v-for="(category, i) in stage.category" :key="i">-->
<!--                    <ul>-->
<!--                      {{category}}-->
<!--                    </ul>-->
<!--                  </div>-->
                </div>
                <div style="position: relative;">
                  <img :src=stage.icon alt="" style=""/>
                  <div v-if="stage.is_started" class="">
                    <p v-if="stage.is_pass" class="pass tw-pt-1.5" style="position: absolute; top: 17%;">Passed</p>
                    <p v-else class="fail tw-pt-1.5" style="position: absolute; top: 15%;">Failed</p>
                  </div>

                </div>
              </v-card>
            </div>
        </div>

        <div class="tw-pt-10 tw-flex tw-justify-end">
          <BaseButton width="85px" id="beginAssessment" depressed
                      style="border-radius: 8px !important;box-shadow: 1px 4px 4px #D5EDFF !important;"
                      button-text="Next" @click="$router.push({query:{tab:'3'}})"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
export default {
  name: "AssessmentStage",
  components: {BaseButton},
  // mounted() {
  //   this.assessmentStages[1].is_started = true
  // },
  data () {
    return {
      assessmentStages: [
        {
          level: "STAGE 1",
          stageName: "Pre-Assessment",
          // category: ["Interview", "Skills set"],
          icon: require("@/assets/video.svg"),
          is_pass: false,
          is_started: false
        },
        {
          level: "STAGE 2",
          stageName: "Algorithm Assessment",
          // category: ["Third test of Hakerearth"],
          icon: require("@/assets/book-open.svg"),
          is_pass: false,
          is_started: false
        },
      ],

      passBorder: 'pass-border',
      failBorder: 'fail-border',
      cardBorder: 'card',
      cardStage: ' card-active'
    }
  },

  methods:{
    goBack() {
      this.$router.go(-1)
    },
    getBorderColor(val){
      if (val.is_pass &&val.is_started){
        return this.passBorder
      }else if (!val.is_pass&&val.is_started){
        return this.failBorder
      }
      if (val.level === 'STAGE 2'){
        return this.cardBorder
      }else {
        return this.cardStage
      }
    }
  },
}

</script>

<style scoped>
.text {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #001343;
}
.card {
  width: 402px;
  min-height: 78px;
  background: #FFFFFF;
  color: #D0DCE4;
  border: 1px solid #D0DCE4;
  border-radius: 8px;
}
ul{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  list-style: disc;
  align-items: center;
  letter-spacing: 0.01em;
  color: #475661;
}
h4{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
}
.card-active {
  width: 402px;
  min-height: 78px;
  border-radius: 8px;
  color: #45B4FF;
  background: #F7FCFF;
  border: 1px solid #45B4FF;
}
.name {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;

}
/*.name:hover {*/
/*  color: #008EEF;*/
/*}*/
h4 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #475661;
}
.pass {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #0BCA7A;
}
.fail {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #FF2E2E;
}
.pass-border {
  width: 402px;
  min-height: 78px;
  border-radius: 8px;
  color: #001343;
  border: 1.5px solid #0BCA7A;
}
.fail-border {
  width: 402px;
  min-height: 78px;
  color: #001343;
  border-radius: 8px;
  border: 1.5px solid #FF5353;
}
</style>